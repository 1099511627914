<template>
    <div>
        <div class="page-title">口红试色</div>

        <color-plane type="lipstick" @planeChanged="planeChanged">

            <div class="lipstick-list flex flex-left-center">

                <template v-for="(item,ik) in list ">

                    <div :key="ik" class="lipstick-item">

                        <div class="color-item">
                            <div :style="{backgroundColor:item.color}">
                                <div class="lipstick-a">{{ item.a }}%</div>
                            </div>
                        </div>
                        <div class="lipstick-name">{{ item.name }}</div>
                        <div class="lipstick-color">{{ item.color.toUpperCase() }}</div>

                        <div v-if="planeId > 0" class="lipstick-remove" @click="deleteLipstick(item)"></div>
                    </div>

                </template>

                <div v-if="planeId > 0" class="lipstick-item" @click="newColor">

                    <div class="color-item"></div>
                    <div class="lipstick-name">自定义</div>
                    <div class="lipstick-color add-icon">添加颜色</div>

                </div>

            </div>

        </color-plane>

        <at-modal v-model="showLipstickModal" title="添加颜色" @ok="syncColor">
            <div class="at-input-group block">
                <div class="label">
                    <span>请输入色号名称</span>
                    <small>（非必填）</small>
                </div>
                <div>
                    <at-input v-model="params.name" :max-length="7" class="full-with"/>
                </div>
            </div>
            <div class="at-input-group block">
                <div class="label">
                    <span>请输入RGB色号值</span>
                </div>
                <div class="label">
                    <at-input :max-length="7" :value="hexColor" class="full-with" @input="colorChanged"/>
                </div>
                <div class="label">
                    <div class="color-container">
                        <input :value="hexColor" type="color" class="color-input" @input="colorChanged"/>
                        <div class="color-tips">点击选色</div>
                    </div>
                </div>
                <div class="label">
                    <span>颜色透明度( {{ params.a }}% )</span>
                </div>
                <div class="label">
                    <a-slider v-model="params.a" :marks="sliderMarks" :tip-formatter="colorFormatter"/>
                </div>
            </div>
            <div class="clearfix"></div>
        </at-modal>

    </div>
</template>

<script>
import ColorPlane from "@/views/AST/ColorTools/Com/ColorPlane.vue";
import AtModal from "@/components/AtModal.vue";
import AtInput from "@/components/AtInput.vue";
import api from "@/repo/api";

export default {
    name: "Lipstick",
    components: {AtInput, AtModal, ColorPlane},
    data() {
        return {
            planeId: 0,
            showLipstickModal: false,
            params: {
                r: '',
                g: '',
                b: '',
                name: '',
                a: 50,
            },
            hexColor: "#DDDDDD",
            list: [],
            sliderMarks: {
                0: '0%',
                25: '25%',
                50: '50%',
                75: '75%',
                100: '100% (纯色不透明)',
            },
        }
    },
    methods: {
        colorFormatter(value) {
            return `${value}%`
        },
        newColor() {
            this.params = {
                r: '',
                g: '',
                b: '',
                name: '',
                a: 50,
            }
            this.hexColor = '#DDDDDD'
            this.parseColor(this.hexColor)
            this.showLipstickModal = true
        },
        syncColor() {
            this.$loading.show()

            this.params.plane_id = this.planeId

            if (!this.params.name) {
                this.params.name = this.hexColor
            }

            api.post('/ast-color-plane/sync-lipstick', this.params, data => {

                this.$loading.hide()

                this.$message.data(data)

                if (data.code === 0) {
                    this.showLipstickModal = false
                    this.getLipsticks()
                }

            })
        },
        planeChanged(planeId) {
            this.planeId = planeId
            this.getLipsticks()
        },
        deleteLipstick(item) {
            this.$confirm({
                title: '确定要删除这个颜色吗？',
                onOk: () => {
                    api.post('/ast-color-plane/del-lipstick', {
                        id: item.id,
                        plane_id: this.planeId
                    }, data => {

                        this.$message.data(data)

                        if (data.code !== 0) return;

                        this.getLipsticks()
                    })
                }
            })
        },
        getLipsticks() {

            api.get('/ast-color-plane/lipsticks', {plane_id: this.planeId}, data => {

                if (data.code !== 0) return;

                data.data.forEach(item => {

                    item.color = this.rgbToHex(
                        item.r,
                        item.g,
                        item.b
                    )

                })

                this.list = data.data;

            })
        },
        colorChanged(e) {
            let color = e.currentTarget.value
            this.parseColor(color)
        },
        parseColor(color) {
            let rgb = this.hexToRgb(color) || {}

            this.params.r = rgb['r'];
            this.params.g = rgb['g'];
            this.params.b = rgb['b'];

            this.hexColor = color

            this.$forceUpdate()
        },
        rgbToHex(r, g, b) {
            // 将每个颜色分量转换为两位的十六进制数
            // 使用`toString(16)`将数字转换为十六进制字符串，不足两位的前面补0
            let hexR = r.toString(16).padStart(2, '0');
            let hexG = g.toString(16).padStart(2, '0');
            let hexB = b.toString(16).padStart(2, '0');

            // 将三个十六进制字符串组合起来，并在前面加上`#`
            return `#${hexR}${hexG}${hexB}`;
        },
        hexToRgb(hex) {
            // 移除前缀#符号
            hex = hex.replace(/^\s*#|\s*$/g, '');

            // 将三位十六进制数转换为十进制
            if (hex.length === 3) {
                hex = hex.replace(/(.)/g, '$1$1');
            }

            // 提取R、G、B各自的十六进制表示方式
            var rgb = /^(\w{2})(\w{2})(\w{2})$/.exec(hex);

            // 转为10进制并返回
            return rgb ? {
                r: parseInt(rgb[1], 16),
                g: parseInt(rgb[2], 16),
                b: parseInt(rgb[3], 16)
            } : null;
        },
    }
}
</script>

<style scoped lang="less">
.color-tips {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #0000006e;
    color: white;
    height: 40px;
    text-align: center;
    padding-top: 7px;
    pointer-events: none;
}

.lipstick-item {
    width: 96px;
    height: 148px;
    border-radius: 4px;
    border: 1px solid #EEEEEE;
    padding: 16px 10px;
    cursor: pointer;

    margin-right: 12px;
    margin-bottom: 12px;
    position: relative;
}

.lipstick-list {
    flex-wrap: wrap;
}

.color-item {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-image: url("/assets/icons/color-add-icon.png");
    background-size: cover;
    margin-left: 6px;
    overflow: hidden;


    div {
        width: 100%;
        height: 100%;
    }
}

.lipstick-name {
    font-size: 12px;
    color: #333333;
    line-height: 16px;
    text-align: center;
    margin-top: 8px;
}

.lipstick-color {
    margin-top: 4px;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    border: 1px solid #EEEEEE;
}

.add-icon {
    height: 24px;
    background: rgba(251, 176, 59, 0.1);
    border-radius: 0;
    border: 1px solid #FBB03B;
    color: #FBB03B;
}


.color-container {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    margin: 0 auto;

    input {
        width: 160px;
        height: 160px;
        left: -20px;
        top: -20px;
        position: absolute;
    }
}

.lipstick-remove {
    width: 12px;
    height: 12px;
    background-image: url("/assets/icons/close-btn-black.png");
    background-size: cover;
    position: absolute;
    right: 4px;
    top: 4px;
    opacity: 0;
}

.lipstick-item:hover .lipstick-remove {
    opacity: 1;
}

.lipstick-a {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>